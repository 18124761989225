import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ProductCy from "../../components/ProductCy"
import "../../components/styles/products.scss"
import { StaticImage } from "gatsby-plugin-image"

export default function index() {
  return (
    <Layout>
      <Seo title="Products" />
      <h1 style={{ marginTop: "113px", textAlign: "center" }}>PRODUCTS</h1>
      <div className="products_container">
        <ProductCy />
      </div>
      <div style={{ marginTop: "23px" }} className="otherproducts_container">
        <div className="otherproducts">
          <div className="l3">Other Products</div>
          <h2>ALLIED RETAIL BUSINESS</h2>
          <div className="otherimg_grid">
            <div className="image_text">
              <div className="op_image">
                <StaticImage
                  src="../../images/op/GasConnection.webp"
                  alt="GAS CONNECTION"
                  placeholder="none"
                />
              </div>
              <div className="l1">GAS CONNECTION</div>
            </div>

            <div className="image_text">
              <div className="op_image">
                <StaticImage
                  src="../../images/op/GasPipe.jpg"
                  alt="GAS PIPE"
                  placeholder="none"
                />
              </div>
              <div className="l1">GAS PIPE</div>
            </div>

            <div className="image_text">
              <div className="op_image">
                <StaticImage
                  src="../../images/op/SURAKSHA-HOSE.jpg"
                  alt="GAS SAFETY DEVICE"
                  placeholder="none"
                />
              </div>
              <div className="l1">GAS SAFETY DEVICE</div>
            </div>

            <div className="image_text">
              <div className="op_image">
                <StaticImage
                  src="../../images/op/LPG-CYLINDER-TROLLEY.jpg"
                  alt="LPG CYLINDER TROLLEY"
                  placeholder="none"
                />
              </div>
              <div className="l1">LPG CYLINDER TROLLEY</div>
            </div>

            <div className="image_text">
              <div className="op_image">
                <StaticImage
                  src="../../images/op/GAS-STOVE-SS-2BURNER.jpg"
                  alt="GAS STOVE SS 2BURNER"
                  placeholder="none"
                />
              </div>
              <div className="l1">GAS STOVE SS 2BURNER</div>
            </div>

            <div className="image_text">
              <div className="op_image">
                <StaticImage
                  src="../../images/op/GAS-STOVE-SS-3BURNER.jpg"
                  alt="GAS STOVE SS 3BURNER"
                  placeholder="none"
                />
              </div>
              <div className="l1">GAS STOVE SS 3BURNER</div>
            </div>

            <div className="image_text">
              <div className="op_image">
                <StaticImage
                  src="../../images/op/GAS-STOVE-SS-4BURNER.jpg"
                  alt="GAS STOVE Ss 4BURNER"
                  placeholder="none"
                />
              </div>
              <div className="l1">GAS STOVE SS 4BURNER</div>
            </div>

            <div className="image_text">
              <div className="op_image">
                <StaticImage
                  src="../../images/op/GAS-STOVE-GLASS-TOP-2BURNER.jpg"
                  alt="GAS STOVE GLASS TOP 2BURNER"
                  placeholder="none"
                />
              </div>
              <div className="l1">GAS STOVE GLASS TOP 2BURNER</div>
            </div>

            <div className="image_text">
              <div className="op_image">
                <StaticImage
                  src="../../images/op/GAS-STOVE-GLASS-TOP-3BURNER.jpg"
                  alt="GAS STOVE GLASS TOP 3BURNER"
                  placeholder="none"
                />
              </div>
              <div className="l1">GAS STOVE GLASS TOP 3BURNER</div>
            </div>

            <div className="image_text">
              <div className="op_image">
                <StaticImage
                  src="../../images/op/GAS-STOVE-GLASS-TOP-4BURNER.jpg"
                  alt="GAS STOVE GLASS TOP 4BURNER"
                  placeholder="none"
                />
              </div>
              <div className="l1">GAS STOVE GLASS TOP 4BURNER</div>
            </div>

            <div className="image_text">
              <div className="op_image">
                <StaticImage
                  src="../../images/op/LighterRefil.jpg"
                  alt="FLAME-LIGHTER"
                  placeholder="none"
                />
              </div>
              <div className="l1">FLAME LIGHTER</div>
            </div>

            <div className="image_text">
              <div className="op_image">
                <StaticImage
                  src="../../images/op/FLAME-RETARDANT-APRON.jpg"
                  alt="FLAME RETARDANT APRON"
                  placeholder="none"
                />
              </div>
              <div className="l1">FLAME RETARDANT APRON</div>
            </div>

            {/* <div className="image_text">
              <div className="op_image">
                <StaticImage
                  src="../../images/op/AGARBATTI.jpg"
                  alt="AGARBATTI"
                  placeholder="none"
                />
              </div>
              <div className="l1">AGARBATTI</div>
            </div> */}

            <div className="image_text">
              <div className="op_image">
                <StaticImage
                  src="../../images/op/FIRE-EXTINGUISHER.jpg"
                  alt="FIRE EXTINGUISHER"
                  placeholder="none"
                />
              </div>
              <div className="l1">FIRE EXTINGUISHER</div>
            </div>

            {/* <div className="image_text">
              <div className="op_image">
                <StaticImage
                  src="../../images/op/GAS-LEAK-SENSOR.jpg"
                  alt="GAS LEAK SENSOR"
                  placeholder="none"
                />
              </div>
              <div className="l1">GAS LEAK SENSOR</div>
            </div> */}

            {/* <div className="image_text">
              <div className="op_image">
                <StaticImage
                  src="../../images/op/PPE.jpg"
                  alt="PPE"
                  placeholder="none"
                />
              </div>
              <div className="l1">PPE</div>
            </div> */}

            {/* <div className="image_text">
              <div className="op_image">
                <StaticImage
                  src="../../images/op/POS.jpg"
                  alt="POS"
                  placeholder="none"
                />
              </div>
              <div className="l1">POS</div>
            </div> */}

            {/* <div className="image_text">
              <div className="op_image">
                <StaticImage
                  src="../../images/op/SOLAR-LIGHTS.jpg"
                  alt="SOLAR-LIGHT"
                  placeholder="none"
                />
              </div>
              <div className="l1">SOLAR LIGHT</div>
            </div> */}

            <div className="image_text">
              <div className="op_image">
                <StaticImage
                  src="../../images/op/TEA-POWDER.jpg"
                  alt="TEA POWDER"
                  placeholder="none"
                />
              </div>
              <div className="l1">TEA POWDER</div>
            </div>

            {/* //// */}
          </div>
        </div>
      </div>
    </Layout>
  )
}
